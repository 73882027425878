.theme--default {
  --purple: #38149d;
  --purple10: #d5d1df;
  --purple30: #b8b0db;
  --white: #ffffff;
  --gray: #e5e5e5;
  --blue: #eff3f6;
  --cta: #3674d0;
  --red: #ee4a4a;
}
