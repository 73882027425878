p {
  font-family: "Inter", "Arial", sans-serif;
}

.text-color--purple {
  color: var(--purple);
}
.text-color--white {
  color: var(--white);
}

p.default {
  font-size: 16px;
}
p.caption {
  font-size: 12px;
  font-weight: 400;
}

p.error {
  color: var(--red);
  font-size: 12px;
  font-weight: 400;
}
