*,
*:after,
*:before {
  box-sizing: border-box;
}

.hide-on-desktop {
  display: none;
}

@media (max-width: 1000px) {
  .hide-on-mobile {
    display: none;
  }

  .hide-on-desktop {
    display: block;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll {
  overflow: hidden;
}

.no-transition {
  transition: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Telegraf";
  src: url("./fonts/telegraf/Telegraf-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Telegraf";
  src: url("./fonts/telegraf/Telegraf-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Telegraf";
  src: url("./fonts/telegraf/Telegraf-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Telegraf";
  src: url("./fonts/telegraf/Telegraf-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Telegraf";
  src: url("./fonts/telegraf/Telegraf-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Telegraf";
  src: url("./fonts/telegraf/Telegraf-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Telegraf";
  src: url("./fonts/telegraf/Telegraf-UltraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Telegraf";
  src: url("./fonts/telegraf/Telegraf-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
