.linkgroup {
  display: flex;
  flex-flow: row wrap;
  grid-gap: 16px;

  margin-bottom: 16px;
}

.linkgroup a {
  text-decoration: none;
}
