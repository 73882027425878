.input {
  font-family: "Inter", "Arial", sans-serif;
  color: var(--purple);

  width: fit-content;

  position: relative;
}
.input:not(.with-label) {
  overflow: hidden;
}

.input-color--blue {
  background-color: var(--blue);
}
.input-color--white {
  background-color: var(--white);
}

.input-input {
  font-family: "Inter", "Arial", sans-serif;
  color: var(--purple);
  font-size: 1em;

  width: 100%;
  height: 40px;
  padding-left: 16px;
  padding-right: 52px;

  border: none;
  outline: none;
  border-radius: 8px;
}

.input-placeholder {
  color: var(--purple30);

  position: absolute;
  left: 16px;
  top: 50%;

  display: flex;
  justify-content: space-between;

  width: calc(100% - 32px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  transform: translateY(-50%);

  pointer-events: none;
}
.input.with-label .input-placeholder {
  transition: ease-in-out 0.1s;
}
.input.active .input-placeholder {
  color: var(--purple);
  font-size: 12px;

  top: -4px;

  transform: translateY(-100%);
  transition: ease-in-out 0.1s;
}

.input-autocomplete {
  position: absolute;
  left: 16px;
  top: 50%;

  transform: translateY(-50%);

  opacity: 0;

  pointer-events: none;
}
.input-autocomplete--value {
  opacity: 0;
}
.input.active .input-autocomplete {
  color: var(--purple30);

  max-width: calc(100% - 64px);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
}

.input-icon {
  position: absolute;
  right: 16px;
  top: 50%;

  transform: translateY(-50%);
}

.input-icon.can-submit:hover {
  cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.input-error {
  display: inline-block;
}

.input-error p {
  margin: 0;
}
