.overview .details {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: auto auto auto auto 1fr;
  grid-gap: 16px;

  max-width: 100%;
}

.overview .details .description {
  width: 100%;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.overview .details .related {
  width: 100%;
  grid-column: 2 / 3;
  grid-row: 3 / 5;
}

.overview .details .context {
  width: 100%;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}

.overview .details .trend {
  width: 100%;
  grid-column: 1 / 2;
  grid-row: 2 / 6;
}

.overview .details .metadata {
  grid-column: 2 / 3;
  grid-row: 5 / 6;

  display: flex;
  flex-flow: row wrap;
  grid-gap: 16px;
  justify-content: flex-start;
  height: min-content;
}

.sort-filter {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;

  margin-bottom: 32px;
}

.sort-filter .card {
  width: auto;
  max-width: 100%;

  height: min-content;
}

.overviewColumns {
  display: grid;
  grid-template-columns: 1fr 30vw;
  grid-gap: 64px;

  max-width: 100%;
}

.contextual {
  height: 100%;
}

.contextual-inner {
  position: sticky;
  top: 32px;

  background-color: var(--white);
  border-radius: 16px;

  padding: 32px;

  overflow-x: hidden;
  overflow-y: auto;

  max-height: calc(100vh - 64px);
}

.contextual-inner h2:first-child {
  margin-top: 16px;
}

.selectGroup {
  display: flex;
  flex-flow: row wrap;

  margin: 0;
  margin-bottom: 8px;
}

.selectGroup > .button {
  margin-right: 16px;
  margin-bottom: 16px;
}

.contextual-selectGroup {
  margin-bottom: 16px;
}

.contextual-selectGroup .radiogroup {
  width: 100%;
  grid-gap: 16px;
}

.contextual-selectGroup .radiogroup-button {
  margin: 0;
  flex: 1;
}

.contextual-selectGroup .radiogroup-button .button {
  width: 100%;
}

@media (max-width: 1000px) {
  .overview .details {
    display: flex;
    flex-flow: row wrap;
  }

  .sort-filter {
    grid-template-columns: 1fr;

    margin-bottom: 16px;
  }

  .overviewColumns {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  .relevant {
    max-width: calc(100vw - 64px);
    grid-row: 2;
  }

  .contextual {
    position: absolute;
    bottom: 0;
    left: 0;

    height: auto;

    pointer-events: all;

    transform: translateY(100%);
    width: 100vw;

    z-index: 102;

    transition: 0.2s ease-in-out;
  }

  .contextual-outer {
    position: fixed;
    top: 0;
    left: 0;

    pointer-events: none;

    width: 100vw;
    height: 100%;

    transition: 0.2s ease-in-out;
  }

  .contextual-inner {
    position: relative;

    max-height: calc(100vh - 96px);

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    padding-top: 0;

    top: 0;
  }

  .contextual.peeking {
    transform: translateY(calc(100% - 64px));
  }

  .contextual.showing {
    transform: translateY(0);
  }

  .contextual .contextual-toggle {
    display: grid;
    place-content: center;

    width: 100vw;
    height: 64px;
    margin-left: -32px;

    position: sticky;
    top: 0px;
    background-color: var(--white);
  }

  .contextual .contextual-toggle div {
    transform: scaleY(-1);

    transition: 0.2s ease-in-out;
  }

  .contextual.showing .contextual-toggle div {
    transform: scaleY(1);
  }
}
