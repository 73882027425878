.results {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

.featured {
  width: 100%;
}

.more {
  width: 100%;
}

.selectGroup {
  margin: 32px 0;
}

.result {
  width: 100%;

  margin-bottom: 16px;
}

.resultData {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  grid-row-gap: 4px;
  grid-column-gap: 16px;

  max-width: fit-content;
  max-height: fit-content;
}

.resultData p {
  margin: 0;
}

@media (max-width: 1000px) {
  .results,
  .result {
    max-width: 100%;
  }

  .featured,
  .more {
    max-width: calc(100vw - 64px);
  }

  .selectGroup {
    margin: 0;
    margin-top: 16px;
  }
}
