.landing {
  width: 100vw;
  height: 100vh;

  display: grid;
  place-content: center;

  background: var(--white);
}

.tagline {
  max-width: 60ch;
}

@media (max-width: 1000px) {
  .landing {
    padding: 0 32px;
  }
}
