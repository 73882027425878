.head-to-head {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  column-gap: 16px;
}

.head-to-head h4,
.head-to-head h6,
.head-to-head p {
  margin: 0;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.head-to-head h4 {
  align-self: flex-end;
}

.head-to-head p {
  grid-column: 1 / 3;
  width: 100%;

  margin-bottom: 0;
}
