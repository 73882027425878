.details {
  display: flex;
  flex-flow: column;
  grid-gap: 16px;
}

.details h2 {
  margin-bottom: 0;
}

.metadata h3 {
  margin: 0;
}

.metadata p {
  margin: 0;
}

@media (max-width: 1000px) {
  .details {
    max-width: calc(100vw - 64px);
  }
}
