#linegraph {
  font-family: "Inter";
  width: 100%;
  overflow: visible;
}

#linegraph #path path {
  stroke-opacity: 1;
  stroke: var(--cta);
}

#linegraph line,
#linegraph path {
  stroke-opacity: 0.1;
  stroke: var(--purple);
}

#linegraph text {
  font-family: "Inter";
  color: var(--purple);
}

#linegraph #dot {
  font-size: 0.75rem;
  fill: var(--purple);
  color: var(--purple);

  text-align: center;

  pointer-events: none;
}

#linegraph #name {
  font-weight: italic;
}

#linegraph #date {
  font-weight: bold;
}
