.navbar {
  position: relative;
  top: 0;
  left: 0;

  transform: translateY(100%);
  margin-top: -96px;

  width: 100%;
  height: 96px;
  padding: 0 64px;

  display: flex;
  align-items: center;

  z-index: 101;
}

.logo {
  flex: 1;
  padding: 32px 0;
}

.logo a {
  text-decoration: none;
  padding: 32px;

  margin-left: -32px;
}

.navbar > div:not(.logo) {
  padding-left: 16px;
  padding-right: 16px;
}

@media (max-width: 1000px) {
  .navbar {
    padding: 0 32px;
  }

  .navbar .cta-button {
    padding: 0;
  }

  .navbar > div:not(.logo) {
    padding-left: 4px;
    padding-right: 4px;
  }
}
