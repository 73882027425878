.textlink {
  font-family: "Inter", "Arial", sans-serif;
  width: fit-content;
  color: var(--purple);
}

.textlink .button-label--text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textlink-custom,
.textlink-custom p {
  display: inline;

  text-decoration: underline;
}
