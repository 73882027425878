.dropdown {
  font-family: "Inter", "Arial", sans-serif;
  color: inherit;

  position: relative;
}

.dropdown.dropdown-variant--text {
  display: inline-block;
}

.dropdown.dropdown-variant--text .dropdown-text:hover {
  cursor: pointer;
}

.dropdown.dropdown-variant--text .dropdown-text--inner {
  display: flex;
  flex-flow: row;
  grid-gap: 0.5ch;
}

.dropdown.dropdown-variant--button .dropdown-card {
  position: absolute;
  top: -8px;
  left: -8px;
}

.dropdown.dropdown-variant--text .dropdown-card {
  position: absolute;

  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-card {
  user-select: none;
  z-index: 1001;
}

.dropdown-card .card {
  display: flex;
  flex-flow: row wrap;
  grid-gap: 8px;
}

.dropdown-button {
  width: 100%;
}

.dropdown-button .button {
  width: 100%;
}

.dropdown-button .button-label--text {
  white-space: nowrap;
}
