.signup {
  width: 100vw;
  height: 100vh;

  display: grid;
  place-content: center;

  background: var(--blue);
}

.tagline {
  max-width: 60ch;
}

.spacer {
  height: 2rem;
  width: 100%;
}

.signup-inputs {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 32px;
}

.signup-inputs--cta {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-gap: 32px;

  align-items: center;
}

.top-left-cloud {
  position: fixed;
  top: 2vh;
  left: 2vw;
}

.bottom-right-cloud {
  position: fixed;
  bottom: 8vh;
  right: 10vw;
}
