.radiogroup {
  font-family: "Inter", "Arial", sans-serif;

  display: flex;
  flex-flow: row wrap;

  width: fit-content;
}

.radiogroup-color--blue {
  background-color: var(--blue);
}
.radiogroup-color--white {
  background-color: var(--white);
}

.radiogroup-button {
  margin-right: 16px;
  margin-bottom: 16px;
}
