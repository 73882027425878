.search {
  min-height: 100vh;

  background-color: var(--blue);

  display: grid;
  grid-template-columns: auto 1fr;
}

.sidebar {
  min-height: 100vh;
  max-width: 50ch;

  background-color: var(--white);

  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  padding: 20vh 64px;
}

.main {
  padding: 20vh 7.5ch;
}

@media (max-width: 1000px) {
  .search {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  .sidebar {
    min-height: unset;
    max-width: 100vw;
    width: 100vw;

    border-top-left-radius: 0;
    border-bottom-left-radius: 16px;

    padding: 112px 32px 48px 32px;
  }

  .main {
    padding: 48px 32px;
    max-width: 100vw;
  }
}
